import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { FaArrowRight } from "react-icons/fa";
import { ClustBoardMenu, ClusterBoardComponent } from 'platform/cluster/styles/board.style';
import ClusterBoardSvg from 'platform/cluster/components/ClusterBoardSvg';
import CommonBoard from './CommonBoard';
import { getCookie } from 'preferences/cookie/cookie';

const ClusterBoard = () => {
  const location = useLocation();
  const clusterId = location.pathname.split('/platform/cluster')[0];
  const clusterPath = clusterId + '/platform/cluster';

  const navigate = useNavigate();

  const handleLink = (type) => {
    switch (type) {
      case 'equipment': return navigate(`${clusterPath}/equipment/request`);
      case 'people': return navigate(`${clusterPath}/people/request`);
      case 'purchase': return navigate(`${clusterPath}/common/purchase`);
      case 'sales': return navigate(`${clusterPath}/common/sales`);
      case 'board': return navigate(`${clusterPath}/board/public`);

      default: return navigate(`${clusterPath}`);
    }
  }

  return (
    <ClusterBoardComponent className='home'>
      <div>
        <ClustBoardMenu onClick={() => { handleLink('equipment'); }}>
          <h1>장비공유</h1>
          <h4>장비공유</h4>
          <p>필요한 장비를<br />효율적으로 공유하고 활용하세요!</p>
          <ClusterBoardSvg /><FaArrowRight />
        </ClustBoardMenu>
        <ClustBoardMenu onClick={() => { handleLink('people'); }}>
          <h1>인력공유</h1>
          <h4>인재들과의 협업</h4>
          <p>필요한 인재를<br />빠르게 찾고 협력하세요!</p>
          <ClusterBoardSvg /><FaArrowRight />
        </ClustBoardMenu>
        <div className='common-content'>
          <div>
            <h1>공동거래</h1>
            <div>
              <button onClick={() => { handleLink('purchase'); }}>공동구매</button>
              <button onClick={() => { handleLink('sales'); }}>상호거래</button>
            </div>
          </div>
          {getCookie('accessToken') && <CommonBoard />}
        </div>
        <ClustBoardMenu onClick={() => { handleLink('board'); }}>
          <h1>게시판</h1>
          <ClusterBoardSvg /><FaArrowRight />
        </ClustBoardMenu>
      </div>
    </ClusterBoardComponent>
  );
};

export default ClusterBoard;